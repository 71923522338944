import React, { useContext } from 'react'
import { Container, Box, useMediaQuery, Typography, Grid, Hidden } from '@material-ui/core'
import Button from '../../../Button'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import AuthContext from '../../../../context/auth/authContext'


const Hero = (props) => {
    const { title, body } = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile= useMediaQuery(theme.breakpoints.down('xs'));

  const { isAuthenticated } = useContext(AuthContext)
  const link = isAuthenticated ? '/dashboard' : '/user/login'


    return (
        <Box id='hero' position='relative' paddingTop={isMobile ? '4rem' : '9rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={12} md={5}>
                            <Box marginTop="8rem">
                                <Typography variant="h1" className={classes.headerTitle} gutterBottom>
                                    {title}
                                </Typography>
                                <Typography variant="body1" className={classes.headerSubtitle}>
                                    {body}
                                </Typography>
                                <Box marginTop="2rem">
                                    <Button variant="contained" size="large" link to={link} color="primary">
                                        Get Started
                                    </Button>
                                    {/* <Link to="/partner" style={{textDecoration: "none"}}>
                                    <Typography variant="body2" color="primary" className={classes.partner}>Partner With Us</Typography>
                                    </Link> */}
                                   
                                </Box>
                            </Box>
                        </Grid>
                        <Hidden smDown>
                        <Grid item xs={12} sm={12} md={7}>
                                <Box display='flex' justifyContent='flex-end' height='100%'>
                                    <div className={classes.imageWrapper}>

                                    </div>
                                </Box>
                        </Grid>
                        </Hidden>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

Hero.propTypes = {

}

export default Hero
