import React from 'react'
import Hero from './Hero'
import About from './About'
import Video from './Video'
import Why from './Why'
import WhoFor from './WhoFor'
import Steps from './Steps'
import Services from './Services'
import Loans from './Loans'
import Testimonials from './Testimonials'
import Faq from './Faq'
import Refer from './Refer'
import data from '../Data'
import Feedback from './Feedback'

const HomeLayout = props => {
    const { 
        hero,
        about,
        video,
        why,
        whoFor,
        steps,
        services,
        loans,
        testimonials,
        faq
    } = data

    return (
        <>
            <Hero
                data={hero}
            />

           <About
                data={about}
           />

           <Video
                data={video}
           />

          <Services
                data={services}
           />

           <Why
                data={why}
           />

           <WhoFor
                data={whoFor}
           />

           <Steps
                data={steps}
           />

           <Loans
                data={loans}
           />

            <Refer />

            <Faq
               title={faq.title}
               QandA={faq.QandA.slice(0, 3)}
           />

           <Testimonials
                data={testimonials}
           />

          

           <Feedback />
        </>
    )
}

HomeLayout.propTypes = {

}

export default HomeLayout
