import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    view: {
        width: "63%",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
    }
}))

export { useStyles }