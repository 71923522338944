import React, { useContext } from 'react'
import { Container, Box, useMediaQuery, Typography, Grid } from '@material-ui/core'
import Button from '../../../Button'
import { useTheme } from '@material-ui/styles';
import { useStyles } from './style'
import AuthContext from '../../../../context/auth/authContext'



const About = props => {
    const { title, body } = props.data
    const classes = useStyles(props)
    const theme = useTheme();
    const isMobile= useMediaQuery(theme.breakpoints.down('sm'));

    const { isAuthenticated } = useContext(AuthContext)
    const link = isAuthenticated ? '/dashboard' : '/user/login'


    return (
        <Box id='about' position='relative' paddingTop={'5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box>
                    <Grid container direction={isMobile ? "column-reverse" : "row"} spacing={4}>
                        <Grid item xs={12} sm={12} md={7}>
                            <Box display='flex' justifyContent='flex-start' height='100%'>
                                <div className={classes.imageWrapper}>

                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                            <Box marginTop="8rem">
                                <Typography variant="h1" className={classes.headerTitle} gutterBottom>
                                    {title}
                                </Typography>
                                <Typography variant="body1" className={classes.headerSubtitle}>
                                    {body}
                                </Typography>
                                <Box marginTop="2rem">
                                    <Button variant="contained" size="large" link to={link} color="primary">
                                        Apply Now
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

About.propTypes = {

}

export default About
