import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F2F8FF',
    },
    imageWrapper: {
        width: '100%',
        maxWidth: '38rem',
        height: '35rem',
        backgroundImage : props => `url(${props.data.imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '4px',
        marginLeft: '6rem',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            height: '28rem',
            marginLeft: 0
        },
    },
    action: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            justifyContent: 'space-around'
          },
        
    },
    partner: {
        marginLeft: "1rem",
        // [theme.breakpoints.down("xs")]: {
        //     marginTop: "1rem",
        //     marginLeft: "0"
        //   },
    }
}))

export { useStyles }