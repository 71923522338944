import React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { Modal as MuiModal, Container } from '@material-ui/core'
import Section from '../Section'

const Modal = (props) => {
    const classes = useStyles();
    const { view, embedUrl } = props

    return (
        <MuiModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.openModal}
        onClose={props.handleClose}
        disableBackdropClick={props.disableBackdropClick}
        >
            <Container className={classes.container} >
                <Section paddingTop="8rem">
                    { view === "video" && 
                        (
                            <div className={classes.embedContainer}>
                                <iframe width="560" height="315" src={embedUrl} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="modalFrame"></iframe>
                            </div> 
                        )                            
                    }
                    {/* <Paper padding="3rem 4rem">
                        
                    </Paper> */}
                </Section>
            </Container>
        </MuiModal>
    )
}

Modal.propTypes = {
    handleClose : PropTypes.func,
    openModal : PropTypes.bool,
    view : PropTypes.string,
    purpose : PropTypes.string,
    disableBackdropClick : PropTypes.bool,
    amount : PropTypes.string
}

export default Modal
