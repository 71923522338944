import React from 'react'
import { Container, Box, Typography } from '@material-ui/core'
import { useStyles } from './style'

const Services = props => {
    const { serviceTitle, body, services } = props.data
    const classes = useStyles(props)


    return (
        <Box id='services' position='relative' paddingTop={'2rem'} paddingBottom={'3rem'} className={classes.root}>
            <Container maxWidth={'lg'}>
                <Typography variant="h2" align="center" gutterBottom>
                    {serviceTitle}
                </Typography>
                <Container maxWidth={'sm'} align="center">
                    <Typography variant="body1" align="center">
                        {body}
                    </Typography>
                    <Box className={classes.services}>
                        {services.map((service, index) => {
                            const { title, icon} = service
                            return (
                                <Box key={index}>
                                    <Box className={classes.serviceIcon}>{icon}</Box>
                                    <Typography className={classes.serviceTitle}>{title}</Typography>
                                </Box>
                            )
                        })}
                    </Box>
                </Container>
            </Container>
        </Box>
    )
}

Services.propTypes = {

}

export default Services
