import React, {useState} from 'react'
import { Container, Box, Fab } from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useStyles } from './style'
import Modal from '../../../Modal'

const Why = props => {
    const { videoUrl } = props.data
    const classes = useStyles(props)

    
    const [openModal, setOpenModal] = useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    return (
        <Box id='video' position='relative' paddingTop={'5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box>
                <div className={classes.imageWrapper}>
                    <Fab color="primary" aria-label="play" className={classes.fabButton} onClick={handleOpen}>
                        <PlayArrowIcon />
                    </Fab>
                </div>
                </Box>
            </Container>
            {/* Load Custom Modal COmponent */}
            {openModal === true &&
                (
                    <Modal handleClose={handleClose} openModal={openModal} view='video' embedUrl={videoUrl} />
                )
            }
        </Box>
    )
}

Why.propTypes = {

}

export default Why
