import React from 'react'
import { Container, Box, Typography, Grid } from '@material-ui/core'
import { useStyles } from './style'
import clsx from "clsx"

const Why = props => {
    const { title, reasons } = props.data
    const classes = useStyles(props)
   

    return (
        <Box id='why' position='relative' paddingTop={'5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Typography variant="h2" align="center">
                    {title}
                </Typography>
                <Grid container spacing={4}  className={classes.whys}>
                    {reasons.map((reason, key) => (
                        <Grid key={key} item xs={6} sm={6} md={3}>
                            <div className={key > 1 ? clsx(classes.why, classes.space) : classes.why}>
                                <div className={classes.iconWrapper}>
                                    <img src={`${reason.icon}`} alt="" />
                                </div>
                                <Typography variant="body1" align="center">
                                    {reason.title}
                                </Typography>
                            </div>
                        </Grid>
                    ))

                    }
                    
                </Grid>
            </Container>
        </Box>
    )
}

Why.propTypes = {

}

export default Why
