import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#dce4f2"

    },
    services: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "2rem",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-around",
        },
    },
    serviceIcon: {
        backgroundColor: "#FFFFFF",
        borderRadius: "50%",
        width: "3rem",
        height: "3rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
    serviceTitle: {
        marginTop: "0.5rem",
        fontWeight: "600"
    }
    
}))

export { useStyles }