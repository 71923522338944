import React from 'react'
import { Container, Box, Typography, Grid } from '@material-ui/core'
import { useStyles } from './style'

const WhoFor = props => {
    const { data } = props
    const classes = useStyles(props)
    

    return (
        <Box id='whoFor' position='relative' paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Grid container spacing={4} className={classes.whoFors}>
                    {data.map((whoFor, key) => (
                        <Grid key={key} item xs={12} sm={12} md={4}>
                            <div className={classes.whoFor}>
                                <Typography variant="h3" style={{marginBottom: "2rem"}}>
                                    {whoFor.title}
                                </Typography>
                                <Typography variant="body1">
                                    {whoFor.body}
                                </Typography>
                            </div>
                        </Grid>
                    ))

                    }
                    
                </Grid>
            </Container>
        </Box>
    )
}

WhoFor.propTypes = {

}

export default WhoFor