import React from 'react'
import { Container, Box, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'

SwiperCore.use([Navigation]);


const Testimonials = props => {
    const { data } = props
    const classes = useStyles(props)

    return (
        <Box id='testimonial' position='relative' paddingTop={'5rem'} paddingBottom="6.25rem" className={classes.root}>
            <Container maxWidth={'lg'}>
                <Box>
                <Typography variant="h3" align="center" gutterBottom>Testimonials</Typography>
                    <Swiper 
                        className={classes.swiper}
                        navigation
                    >
                    {data.map((testimonial, key) => (
                        <SwiperSlide key={key} className={classes.slide}>
                            <Box padding="0 3rem 0 3rem"  align="center" marginTop={2}>
                                <Box className={classes.view}>
                                <Typography variant="body1" align="center" gutterBottom>
                                    {testimonial.quote}
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {testimonial.name}
                                </Typography>
                                </Box>
                               
                            </Box>
                        </SwiperSlide>
                    ))}
                    </Swiper>
                </Box>
            </Container>
        </Box>
    )
}

Testimonials.propTypes = {

}

export default Testimonials
