import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
    },
    imageWrapper: {
        width: '100%',
        maxWidth: '38rem',
        height: '35rem',
        backgroundImage : props => `url(${props.data.imageUrl})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        borderRadius: '4px',
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            height: '20rem',
        },
    },
}))

export { useStyles }