import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Box,
  useMediaQuery,
  Typography,
  Hidden,
  Grid,
} from "@material-ui/core"
import Carousel, { consts } from "react-elastic-carousel"
import Button from "../../../Button"
import { useTheme } from "@material-ui/styles"
import { Link } from "gatsby"
import { useStyles } from "./style"
import LoanContext from "../../../../context/loan/loanContext"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import AfricaMap from "../../../../images/AfricaMap.svg"
// import Utiva from "../../../../images/Utiva-logo.svg"
// import Advancely from "../../../../images/Advancely-logo.svg"
// import Edutech from "../../../../images/Edutech-logo.svg"
// import Credpal from "../../../../images/credpal-logo2.svg"

const Loans = props => {
  const [partners, setPartners] = useState([])

  const { getPartners } = useContext(LoanContext)

  // const partners = [
  //   {
  //     name: "Advancely",
  //     image: Advancely
  //   },
  //   {
  //     name: "Credpal",
  //     image: Credpal
  //   },
  //   {
  //     name: "Utiva",
  //     image: Utiva
  //   },
  //   // {
  //   name: "Edutech",
  //   image: Edutech
  // },
  //   {
  //     name: "Edutech",
  //     image: Edutech
  //   }
  // ]

  useEffect(() => {
    async function loadPartners() {
      try {
        const res = await getPartners()
        setPartners(res)
      } catch (err) {
        console.log(err)
      }
    }
    loadPartners()
        // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])

  const classes = useStyles(partners?.length)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"))

  const carouselIndicator = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? <NavigateBeforeIcon /> : <NavigateNextIcon />
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    )
  }

  const getWidth = () => {
    let width
    if (isMobile) {
      width = "100%"
    } else {
      if (partners.length > 2) {
        width = "80%"
      } else {
        width = "40%"
      }
    }

    return width
  }

  return (
    <Box position="relative" className={classes.root}>
      <Box className={classes.logos}>
        <Container align="center">
          <Box>
            <Typography variant="h3" gutterBottom>
              Our Partners
            </Typography>
            {partners?.length ? (
              <Carousel
                style={{ width: getWidth(), marginTop: "2rem" }}
                itemsToShow={isMobile ? 2 : 4}
                renderArrow={carouselIndicator}
                showArrows={
                  (isMobile && partners.length > 2) ||
                  (!isMobile && partners.length > 4)
                }
                pagination={false}
                itemPosition={consts.START}
                useKeyboardArrows
              >
                {partners.map((partner, key) => {
                  const { orgName, logoUrl, baseUrl } = partner
                  return (
                    <Box key={key} style={{ width: "100%" }}>
                      <a href={baseUrl} rel="noreferrer">
                      <img
                        src={logoUrl}
                        alt={orgName}
                        target="_blank"
                        style={{
                          width: isMobile ? "5rem" : "9rem",
                          height: "2.5rem",
                        }}
                      />
                      </a>
                      
                    </Box>
                  )
                })}
              </Carousel>
            ) : ""}
          </Box>
        </Container>
      </Box>
      <Box className={classes.partner}>
        <Container maxWidth={"lg"}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Box padding={isMobile ? "1rem 1rem 1rem 0" : "2rem 3rem 0 0"}>
                <Typography variant={isMobile ? "h5" : "h3"}>
                  Partner with us and help reduce financial barriers to
                  education in Africa.
                </Typography>
                {/* <Hidden xlUp> */}
                {!isMobile && (
                  <Button
                    variant="outlined"
                    size={"large"}
                    marginTop={isMobile ? "2rem" : "3rem"}
                    component={Link}
                    to={"/partner"}
                    color="primary"
                  >
                    Learn More
                  </Button>
                )}

                {/* </Hidden> */}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                marginLeft={!smallDevice ? "5rem" : "0"}
                align={smallDevice ? "center" : ""}
              >
                <img
                  alt="Africa Map"
                  src={AfricaMap}
                  height={isMobile ? "120" : "250"}
                  width={isMobile ? "300" : "250"}
                />
                <Hidden smUp>
                  <Button
                    variant="outlined"
                    size={"large"}
                    marginTop={isMobile ? "2rem" : "3rem"}
                    component={Link}
                    to={"/partner"}
                    color="primary"
                  >
                    Learn More
                  </Button>
                </Hidden>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  )
}

Loans.propTypes = {}

export default Loans
